import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  EPForJudge,
  EPForJudgeParticipant,
} from "@repo/types/eventParticipation";

import type { RootState } from "./reducers";

export interface NanduJudgingState {
  event: EPForJudge; // current event selected in the UI
  participant: EPForJudgeParticipant; // current participant selected in the UI
  activeEvent: EPForJudge; // current event being judged, may not be necessary
  activeParticipant: EPForJudgeParticipant; // current participant being judged
}

const initialState = {} as NanduJudgingState;

export const slice = createSlice({
  name: "nanduJudging",
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<EPForJudge>) => {
      state.event = action.payload;
    },
    setParticipant: (state, action: PayloadAction<EPForJudgeParticipant>) => {
      state.participant = action.payload;
    },
    setActiveEvent: (state, action: PayloadAction<EPForJudge>) => {
      state.activeEvent = action.payload;
    },
    setActiveParticipant: (
      state,
      action: PayloadAction<EPForJudgeParticipant>
    ) => {
      state.activeParticipant = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEvent,
  setParticipant,
  setActiveEvent,
  setActiveParticipant,
} = slice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getEvent = (state: RootState) => state.nanduJudging.event;
export const getParticipant = (state: RootState) =>
  state.nanduJudging.participant;
export const getActiveEvent = (state: RootState) =>
  state.nanduJudging.activeEvent;
export const getActiveParticipant = (state: RootState) =>
  state.nanduJudging.activeParticipant;

export default slice.reducer;
